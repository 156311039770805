import { global } from './global'
import { store } from '@/store'

let _baseurl = global.baseApiUrl + 'reclamacion/'

export default {
  getReclamaciones() {
    return global.axios.get(_baseurl + 'get-reclamacion?canalAsociadoId=' + store.getters.user.asociado.canalAsociadoId)
  },
  getReclamacionesEmpleado() {
    return global.axios.get(_baseurl + 'get-reclamacion-empleado?empleadoID=' + store.getters.user.empleadoid)
  },
  getLastReclamacionesCerradas() {
    return global.axios.get(_baseurl + 'get-last-reclamacion-cerrada?CanalAsociadoId=' + store.getters.user.asociado.canalAsociadoId)
  },
  getReclamacion(id) {
    return global.axios.get(_baseurl + 'get-reclamacion-by-id?Id=' + id)
  },
  addReclamacion(data) {
    return global.axios.post(_baseurl + 'post-reclamacion', data)
  },
  // addReclamacionEmpleado(data) {
  //   return global.axios.post(_baseurl + 'post-reclamacion-empleado', data)
  // },
  updateReclamacion(data) {
    return global.axios.put(_baseurl + 'put-reclamacion', data)
  },
  deleteReclamacion(data) {
    return global.axios.delete(_baseurl + 'delete-reclamacion', data)
  },
  getReclamacionDetalles(reclamacionId) {
    return global.axios.get(_baseurl + 'get-reclamacion-detalle?reclamacionId=' + reclamacionId)
  },
  getReclamacionEstadosDetalles(reclamacionId) {
    return global.axios.get(_baseurl + 'get-reclamacion-estado-detalle?reclamacionId=' + reclamacionId)
  },
  getReclamaMasivaArchivo(data) {
    return global.axios.post(_baseurl + 'cargar-archivo-masivo', data, { responseType: 'json', headers: { 'Accept': 'application/vnd.ms-excel', 'Content-Type': 'application/json' } })
  },
  getReclamaMasivaArchivoEmpleado(data) {
    return global.axios.post(_baseurl + 'cargar-archivo-masivo-empleado', data, { responseType: 'json', headers: { 'Accept': 'application/vnd.ms-excel', 'Content-Type': 'application/json' } })
  },
  aplicaReclamaMasivaArchivo(data) {
    return global.axios.post(_baseurl + 'aplica-archivo-masivo', data)
  },
  // aplicaReclamaMasivaArchivoEmpleado(data) {
  //   return global.axios.post(_baseurl + 'aplica-archivo-masivo-empleado', data)
  // },
  // aplicaReclamaMasivaArchivoAnexos (data) {
  //   return global.axios.post(_baseurl + 'AplicaArchivoMasivoAnexos', data)
  // },
  getReclamacionesEstados() {
    return global.axios.get(_baseurl + 'get-reclamacion-estado')
  }
}
