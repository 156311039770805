<template>
  <div>
    <v-card-text v-if="!this.detalle">
      <v-alert text type="info">No hay data para mostrar</v-alert>
    </v-card-text>
    <v-card-text v-if="this.detalle">
      <!-- <v-card  outlined class="white pa-2"> -->
      <v-card-title> <span>Confirmación Reclamación</span></v-card-title>
      <v-card-text>
        <v-row wrap>
          <v-col cols="12">
            <v-textarea
              v-model="reclamacion.Comentario"
              placeholder="Comentario"
              required
              autofocus
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-file-input
              accept="*"
              counter
              v-model="fileAnexo"
              chips
              placeholder="Seleccionar anexos"
              :show-size="1000"
              :rules="fileRules"
              style="padding: 0px"
              multiple
            ></v-file-input>
          </v-col>
          <v-col cols="12">
            <v-btn
              class="mr-1"
              color="primary"
              depressed
              @click.stop="aplicar"
              :disabled="!formAplicarIsValid || bloquear"
              >Aceptar</v-btn
            >
            <v-btn depressed text color="error" @click.stop="cancelar"
              >Cancelar</v-btn
            >
          </v-col>
          <v-col cols="12">
            <v-data-table
              :items="detalle"
              v-if="detalle"
              item-key="Telefono"
              :loading="loading"
              :headers="headerDetail"
            >
              <template slot="items" slot-scope="props">
                <td>{{ props.item.Telefono }}</td>
                <td>{{ props.item.ProductCode }}</td>
                <td>{{ props.item.FechaActivacion | date }}</td>
                <td>{{ props.item.Serie }}</td>
                <td>{{ props.item.NumeroOs }}</td>
                <td>
                  <v-edit-dialog :return-value.sync="props.item.Motivo" lazy>
                    {{ props.item.Motivo }}
                    <v-text-field
                      slot="input"
                      v-model="props.item.Motivo"
                      :rules="[max100chars]"
                      label="Edit"
                      counter
                    ></v-text-field>
                  </v-edit-dialog>
                </td>
                <td>
                  <v-edit-dialog :return-value.sync="props.item.Concepto" lazy>
                    {{ props.item.Concepto }}
                    <v-text-field
                      slot="input"
                      v-model="props.item.Concepto"
                      :rules="[max100chars]"
                      label="Edit"
                      counter
                    ></v-text-field>
                  </v-edit-dialog>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card-text>

    <v-fab-transition>
      <v-btn
        v-if="$vuetify.breakpoint.mobile"
        color="transparent"
        absolute
        top
        right
        fixed
        style="top: 120px; right: 0px"
        @click.native="drawerRight = !drawerRight"
      >
        <v-icon>filter_alt</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-navigation-drawer v-model="drawerRight" app clipped right>
      <v-card-text>
        <div class="subtitle-1 py-5">Filtros</div>
        <v-divider></v-divider>
        <br />
        <v-row wrap>
          <v-col cols="12">
            <v-file-input
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              counter
              v-model="file"
              chips
              placeholder="Seleccionar archivo"
              :show-size="1000"
              :rules="fileRules"
            ></v-file-input>
            <a target="_blank" href="static/ReclaMasiva.xlsx"
              >Descargar Plantilla</a
            >
          </v-col>
          <v-col cols="12">
            <div class="mb-5 text-right">
              <v-btn
                class="ma-1"
                depressed
                color="primary"
                @click="CargarArchivo"
                :disabled="!formIsValid || loading"
                :loading="loading"
                >Cargar reclamación
                <span slot="loader">Cargando...</span>
              </v-btn>
            </div>
            <v-progress-linear
              :indeterminate="true"
              v-if="loading"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-card-text>
    </v-navigation-drawer>
  </div>
</template>

<script>
import apiReclamacion from "@/api/reclamaciones";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      drawerRight: true,
      max100chars: (v) => v.length <= 100 || "Input too long!",
      loading: false,
      bloquear: false,
      file: [],
      reclamacion: { Comentario: "" },
      detalle: null,
      headerDetail: [
        { text: "Telefono", value: "Telefono" },
        { text: "Price Plan", value: "ProductCode" },
        { text: "Fecha Activación", value: "FechaActivacion" },
        { text: "Serie", value: "Serie" },
        { text: "Número OS", value: "NumeroOs" },
        { text: "Motivo", value: "Motivo" },
        { text: "Concepto", value: "Concepto" },
      ],
      paginationDetail: {
        sortBy: "Telefono",
        rowsPerPage: 25,
      },
      fileAnexo: [],
      fileRules: [
        (v) =>
          (v && v.size < 4 * 1024 * 1024) ||
          "El archivo que esta intentando subir excede los 4 MB",
      ],
    };
  },
  computed: {
    formIsValid() {
      return this.file.name !== undefined;
    },
    formAplicarIsValid() {
      return (
        this.reclamacion &&
        this.reclamacion.Comentario &&
        this.reclamacion.Comentario !== ""
      );
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage", "clearError"]),
    CargarArchivo() {
      let data = new FormData();
      data.append("EmpleadoId", this.user.empleadoid);
      data.append("CanalId", this.user.canalid);
      data.append("Sistema", "Interno");
      data.append("username", this.user.username);
      data.append("Archivos", this.file);
      this.loading = true;
      apiReclamacion
        .getReclamaMasivaArchivoEmpleado(data)
        .then((response) => {
          // download(response.data, 'ResultadosMasivas' + '.xlsx')
          this.reclamacion = response.data;
          this.detalle = this.reclamacion.Detalle;
          this.loading = false;
          this.clearError();
        })
        .catch((error) => {
          this.loading = false;
          this.setError(error);
        });
    },
    cancelar() {
      this.reclamacion = { Comentario: "" };
      this.detalle = null;
      // this.$router.push('/reclamaciones/masiva')
    },
    aplicar() {
      if (this.reclamacion.Comentario !== "") {
        this.bloquear = true;
        this.reclamacion.Detalle = this.detalle;
        let data = new FormData();
        data.append("reclamacion", JSON.stringify(this.reclamacion));
        for (var key in this.fileAnexo) {
          data.append("Archivos[]", this.fileAnexo[key]);
        }
        apiReclamacion
          .aplicaReclamaMasivaArchivo(data)
          .then((response) => {
            // let reclamacionId = response.data.ReclamacionId
            let numero = response.data.Numero;
            let mensaje = {
              title: "Reclamación",
              body:
                "Se ha creado satisfactoriamente la reclamación número " +
                numero,
              type: "confirm",
            };
            this.detalle = null;
            this.setMessage(mensaje);
            this.bloquear = false;
          })
          .catch((error) => {
            this.setError(error);
            this.bloquear = false;
          });
      }
    },
  },
};
</script>